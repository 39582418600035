import React from "react";
import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  data,
  activeDialog,
  hideActiveDialog,
  switchData,
}) => {
  const activeDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={switchData}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={activeDialog}
      header="Cambiar estado"
      modal
      footer={activeDialogFooter}
      onHide={hideActiveDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {data && (
          <span>
            ¿Estas seguro que quieres {data.estado ? "desactivar" : "activar"}{" "}
            <b>{data.nombre}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
