import React from "react";
import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  asterisk,
  activeAsteriskDialog,
  hideActiveAsteriskDialog,
  switchAsterisk,
}) => {
  const activeAsteriskDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={switchAsterisk}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveAsteriskDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={activeAsteriskDialog}
      header="Cambiar estado asterisk"
      modal
      footer={activeAsteriskDialogFooter}
      onHide={hideActiveAsteriskDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {asterisk && (
          <span>
            ¿Estas seguro que quieres{" "}
            {asterisk.active ? "desactivar" : "activar"}{" "}
            <b>{asterisk.serverName}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
