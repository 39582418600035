import { viewTypes } from "../common/types/views.type";
import AsteriskAbm from "./asterisk/AsteriskAbm";
import GeneralAbm from "./general/GeneralAbm";
import VendorAbm from "./vendor/VendorAbm";

export default function ViewsConfig() {
  const resetView = () => {
    window.location.href = "/configuration";
  };
  const optionView = sessionStorage.getItem("optionView");

  const selectView = () => {
    switch (optionView) {
      case "Asterisks":
        return <AsteriskAbm resetView={resetView} />;
      case "Proveedores":
        return <VendorAbm resetView={resetView} />;
      case "category":
        return (
          <GeneralAbm
            list="categories"
            typeView="category"
            view="Categorías de agrupación"
            viewFunction={viewTypes.vfAbmCategorias}
            resetView={resetView}
          />
        );
      case "groups":
        return (
          <GeneralAbm
            list="groups"
            typeView="group"
            view="Grupos de campañas"
            viewFunction={viewTypes.vfAbmGrupos}
            resetView={resetView}
          />
        );
      default:
        return <NullView />;
    }
  };

  return <>{selectView()}</>;
}

const NullView = () => {
  return <></>;
};
