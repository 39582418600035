import React, { useEffect } from "react";
import {
  Button,
  InputSwitch,
  InputText,
  Toolbar,
  classNames,
} from "primereact";

export const AsteriskPanelContain = ({
  asterisk,
  saveAsterisk,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [asterisk]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!asterisk.serverName.trim()) {
      newErrors.serverName = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.serverName = null;
    }

    if (!asterisk.ip.trim()) {
      newErrors.ip = "Ingresa la ip";
      valid = false;
    } else {
      newErrors.ip = null;
    }

    if (!asterisk.port.trim()) {
      newErrors.port = "Ingresa el puerto";
      valid = false;
    } else {
      newErrors.port = null;
    }

    if (!asterisk.user.trim()) {
      newErrors.user = "Ingresa el usuario";
      valid = false;
    } else {
      newErrors.user = null;
    }

    if (!asterisk.password.trim()) {
      newErrors.password = "Ingresa la contraseña";
      valid = false;
    } else {
      newErrors.password = null;
    }

    if (!asterisk.context.trim()) {
      newErrors.context = "Ingresa el contexto";
      valid = false;
    } else {
      newErrors.context = null;
    }

    if (!asterisk.exten) {
      newErrors.exten = "Ingresa la extención";
      valid = false;
    } else {
      newErrors.exten = null;
    }

    if (!asterisk.orden) {
      newErrors.orden = "Ingresa el orden";
      valid = false;
    } else {
      newErrors.orden = null;
    }

    if (!asterisk.prefix.trim()) {
      newErrors.prefix = "Ingresa el prefijo";
      valid = false;
    } else {
      newErrors.prefix = null;
    }

    if (!asterisk.pathTTs.trim()) {
      newErrors.pathTTs = "Ingresa el pathTTs";
      valid = false;
    } else {
      newErrors.pathTTs = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle asterisk</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveAsterisk();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="serverName">Server Name</label>
            <InputText
              id="serverName"
              value={asterisk.serverName}
              onChange={(e) => onInputChange(e, "serverName")}
              required
              autoFocus
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && errors.serverName,
              })}
              placeholder="Ingresa el nombre del server"
            />
            {errors.serverName && (
              <small className="p-error block">{errors.serverName}</small>
            )}
          </div>

          <div className="field switch">
            <InputSwitch
              checked={asterisk.active}
              onChange={(e) => onInputChange(e, "active")}
            />
            <label htmlFor="active">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="ip">IP</label>
          <InputText
            value={asterisk.ip}
            required
            autoComplete="off"
            placeholder="Ingresa la IP"
            onChange={(e) => onInputChange(e, "ip")}
            className={classNames({
              "p-invalid": submitted && errors.ip,
            })}
          />
          {errors.ip && <small className="p-error block">{errors.ip}</small>}
        </div>

        <div className="field">
          <label htmlFor="port">Puerto</label>
          <InputText
            value={asterisk.port}
            required
            autoComplete="off"
            placeholder="Ingresa el puerto"
            onChange={(e) => onInputChange(e, "port")}
            className={classNames({
              "p-invalid": submitted && errors.port,
            })}
          />
          {errors.port && (
            <small className="p-error block">{errors.port}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="user">Usuario</label>
          <InputText
            value={asterisk.user}
            required
            autoComplete="off"
            placeholder="Ingresa el usuario"
            onChange={(e) => onInputChange(e, "user")}
            className={classNames({
              "p-invalid": submitted && errors.user,
            })}
          />
          {errors.user && (
            <small className="p-error block">{errors.user}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="password">Password</label>
          <InputText
            value={asterisk.password}
            required
            autoComplete="off"
            placeholder="Ingresa el password"
            onChange={(e) => onInputChange(e, "password")}
            className={classNames({
              "p-invalid": submitted && errors.password,
            })}
          />
          {errors.password && (
            <small className="p-error block">{errors.password}</small>
          )}
        </div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="context">Contexto</label>
          <InputText
            value={asterisk.context}
            required
            autoComplete="off"
            placeholder="Ingresa el contexto"
            onChange={(e) => onInputChange(e, "context")}
            className={classNames({
              "p-invalid": submitted && errors.context,
            })}
          />
          {errors.context && (
            <small className="p-error block">{errors.context}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="esten">Extensión</label>
          <InputText
            type="number"
            value={asterisk.exten}
            placeholder="Ingresa la extensión"
            required
            autoComplete="off"
            onChange={(e) => onInputChange(e, "exten")}
            className={classNames({
              "p-invalid": submitted && errors.exten,
            })}
          />
          {errors.exten && (
            <small className="p-error block">{errors.exten}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="estado">Orden</label>
          <InputText
            type="number"
            value={asterisk.orden}
            placeholder="Ingresa el orden"
            required
            autoComplete="off"
            onChange={(e) => onInputChange(e, "orden")}
            className={classNames({
              "p-invalid": submitted && errors.orden,
            })}
          />
          {errors.orden && (
            <small className="p-error block">{errors.orden}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="prefix">Prefijo</label>
          <InputText
            value={asterisk.prefix}
            placeholder="Ingresa el prefijo"
            required
            autoComplete="off"
            onChange={(e) => onInputChange(e, "prefix")}
            className={classNames({
              "p-invalid": submitted && errors.prefix,
            })}
          />
          {errors.prefix && (
            <small className="p-error block">{errors.prefix}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="pathTTs">PathTTs</label>
          <InputText
            value={asterisk.pathTTs}
            placeholder="Ingresa el pathTTs"
            required
            autoComplete="off"
            onChange={(e) => onInputChange(e, "pathTTs")}
            className={classNames({
              "p-invalid": submitted && errors.pathTTs,
            })}
          />
          {errors.pathTTs && (
            <small className="p-error block">{errors.pathTTs}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
