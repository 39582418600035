import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import {
  IGeneralType,
  IGeneralTypeRequest,
  IGeneralTypeRequestCreate,
} from "../../types/general.type";
import generalService from "../../services/general.service";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { GeneralTable } from "./GeneralTable";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { Panel } from "../_slidePanel/Panel";
import { GeneralPanelContain } from "./GeneralPanelContain";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  nombre: string;
  estado: string;
}

export default function GeneralAbm(props) {
  const dataEmpty = {
    id: "",
    nombre: "",
    estado: false,
  };

  const { vnConfiguracion: viewName } = viewTypes;
  const viewFunction = props.viewFunction;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(dataEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [activeDialog, setActiveDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    estado: "",
  };
  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, props]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      name: lazyParams.nombre,
      active: lazyParams.estado,
      pageSize: lazyParams.rows,
      pageNumber: lazyParams.page,
      direction: lazyParams.direction ? direction : null,
      field: lazyParams.sortField,
    };

    loadLazyTimeout = generalService
      .ObtenerData(filter, props.typeView, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data[props.list].map((c) => {
            let parsed: IGeneralType = {
              id: c.id,
              nombre: c.name,
              estado: c.active,
            };
            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const onFilter = (event) => {
    const estado: string = event.filters.estado.value;
    const nombre: string = event.filters.nombre.value;

    setLazyParams((previousState) => {
      return {
        ...previousState,
        estado: estado,
        nombre: nombre,
      };
    });
  };

  const openNew = () => {
    setData(dataEmpty);
    resetErrors();
    setSubmitted(false);
    setIsOpen(true);
  };

  const hideActiveDialog = () => {
    setActiveDialog(false);
  };

  const saveData = () => {
    const generalObj: IGeneralTypeRequestCreate = {
      name: data.nombre,
      active: data.estado,
    };

    if (data.id != "") {
      generalService
        .ModificarData(
          data.id,
          generalObj,
          props.typeView,
          userName,
          viewName,
          viewFunction
        )
        .then((response: any) => {
          try {
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              life: 3000,
            });
            setData(dataEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      generalService
        .CrearData(generalObj, props.typeView, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              life: 3000,
            });
            setData(dataEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setData(dataEmpty);
  };

  const editData = (data) => {
    setData({ ...data });
    resetErrors();
    setSubmitted(false);
    setIsOpen(true);
  };

  const confirmSwitchData = (data) => {
    setData({ ...data });
    setActiveDialog(true);
  };

  const switchData = () => {
    const strState = !data.estado ? "activado" : "desactivado";

    const id = data.id;
    const updatePartial: IGeneralTypeRequest = {
      id: null,
      name: null,
      active: !data.estado,
    };
    generalService
      .updatePartial(
        id,
        updatePartial,
        props.typeView,
        userName,
        viewName,
        viewFunction
      )
      .then((response: any) => {
        try {
          setActiveDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: `${data.nombre} ${strState}`,
            life: 3000,
          });
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el cambio",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || "";
    let _data = { ...data };
    _data[`${name}`] = val;
    setData(_data);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    nombre: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />
          <GeneralTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            initParams={initParams}
            loading={loading}
            footerPage={footerPage}
            view={props.view}
            openNew={openNew}
            editData={editData}
            confirmSwitchData={confirmSwitchData}
            resetView={props.resetView}
          />
          {data && (
            <ConfirmationDialog
              data={data}
              activeDialog={activeDialog}
              hideActiveDialog={hideActiveDialog}
              switchData={switchData}
            />
          )}
          {data && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <GeneralPanelContain
                data={data}
                saveData={saveData}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                view={props.view}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
