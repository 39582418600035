import React, { useEffect } from "react";
import { Button, InputText, Toolbar, classNames } from "primereact";
import PicklistComp from "./PicklistComp";

export const VendorPanelContain = ({
  vendor,
  saveVendor,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  sourceAsterisks,
  setSourceAsterisks,
  retornoPickList,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [vendor]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!vendor.name.trim()) {
      newErrors.name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.name = null;
    }

    if (!vendor.channel) {
      newErrors.channel = "Ingresa el canal";
      valid = false;
    } else {
      newErrors.channel = null;
    }

    if (!vendor.cps) {
      newErrors.cps = "Ingresa el cps";
      valid = false;
    } else {
      newErrors.cps = null;
    }

    if (!vendor.queueName.trim()) {
      newErrors.queueName = "Ingresa la cola";
      valid = false;
    } else {
      newErrors.queueName = null;
    }

    if (!vendor.context.trim()) {
      newErrors.context = "Ingresa el contexto";
      valid = false;
    } else {
      newErrors.context = null;
    }

    if (!vendor.asterisks || vendor.asterisks.length <= 0) {
      newErrors.asterisks = "Selecciona al menos un asterisk";
      valid = false;
    } else {
      newErrors.asterisks = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle proveedor</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveVendor();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="name"
            value={vendor.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoComplete="off"
            autoFocus
            className={classNames({
              "p-invalid": submitted && errors.name,
            })}
            placeholder="Ingresa el nombre del proveedor"
          />
          {errors.name && (
            <small className="p-error block">{errors.name}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="channel">Canal</label>
          <InputText
            id="channel"
            value={vendor.channel}
            onChange={(e) => onInputChange(e, "channel")}
            required
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.channel,
            })}
            placeholder="Ingresa el canal"
          />
          {errors.channel && (
            <small className="p-error block">{errors.channel}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="cps">CPS</label>
          <InputText
            id="channel"
            value={vendor.cps}
            onChange={(e) => onInputChange(e, "cps")}
            required
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.cps,
            })}
            placeholder="Ingresa el cps"
          />
          {errors.cps && <small className="p-error block">{errors.cps}</small>}
        </div>

        <div className="field">
          <label htmlFor="queueName">Cola</label>
          <InputText
            id="channel"
            value={vendor.queueName}
            onChange={(e) => onInputChange(e, "queueName")}
            required
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.queueName,
            })}
            placeholder="Ingresa la cola"
          />
          {errors.queueName && (
            <small className="p-error block">{errors.queueName}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="context">Contexto</label>
          <InputText
            id="channel"
            value={vendor.context}
            onChange={(e) => onInputChange(e, "context")}
            required
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.context,
            })}
            placeholder="Ingresa el contexto"
          />
          {errors.context && (
            <small className="p-error block">{errors.context}</small>
          )}
        </div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="asterisks">Asterisks</label>
          <PicklistComp
            vendor={vendor}
            retornoPickList={retornoPickList}
            sourceAsterisks={sourceAsterisks}
            setSourceAsterisks={setSourceAsterisks}
          />
          {errors.asterisks && (
            <small className="p-error block">{errors.asterisks}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
