import { PickList } from "primereact/picklist";

const PicklistComp = ({
  vendor,
  sourceAsterisks,
  setSourceAsterisks,
  retornoPickList,
}) => {
  /** pickList **/
  const itemTemplate = (item) => {
    return <div>{item.serverName}</div>;
  };

  const onChangeAsterisks = (e) => {
    setSourceAsterisks(e.source);
    retornoPickList(e.target);
  };

  /** */
  return (
    <PickList
      source={sourceAsterisks}
      target={vendor.asterisks}
      itemTemplate={itemTemplate}
      onChange={onChangeAsterisks}
      sourceHeader="Disponibles"
      targetHeader="Seleccionados"
      sourceStyle={{ height: "220px" }}
      targetStyle={{ height: "220px" }}
      filterBy="serverName"
      sourceFilterPlaceholder="Buscar por nombre"
      targetFilterPlaceholder="Buscar por nombre"
      showSourceControls={false}
      showTargetControls={false}
    />
  );
};

export default PicklistComp;
