import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const VendorTable = ({
  customers,
  lazyParams,
  setLazyParams,
  loading,
  footerPage,
  openNew,
  editVendor,
  resetView,
  initParams,
  optionsAsterisks,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Proveedores</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editVendor(rowData)}
        />
      </div>
    );
  };

  const channelBodyTemplate = (rowData) => {
    return (
      <span>
        {rowData.channel} / {rowData.cps}
      </span>
    );
  };

  const asteriskBodyTemplate = (rowData) => {
    return (
      <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
        {rowData.asterisks.map((a) => (
          <li key={a.id}>{a.serverName}</li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
        optionsAsterisks={optionsAsterisks}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="canales"
          showFilterMenu={false}
          header="Canales / CPS"
          body={channelBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="queueName"
          sortable
          showFilterMenu={false}
          header="Cola"
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="context"
          sortable
          showFilterMenu={false}
          header="Contexto"
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="asterisks"
          showFilterMenu={false}
          header="Asterisks"
          body={asteriskBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
