import React, { useState } from "react";
import { InputText, MultiSelect } from "primereact";
import { FilterWrapper } from "../_filterPanel/FilterWrapper";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  optionsAsterisks,
}) => {
  const initFilters = {
    name: "",
    queueName: "",
    context: "",
    asterisks: "",
  };
  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Nombre",
      filtro: "name",
      input: (
        <InputText
          className="filter-text"
          value={filters.name}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Cola",
      filtro: "queueName",
      input: (
        <InputText
          className="filter-text"
          value={filters.queueName}
          placeholder="Ingresa la cola"
        />
      ),
    },
    {
      title: "Contexto",
      filtro: "context",
      input: (
        <InputText
          className="filter-text"
          value={filters.context}
          placeholder="Ingresa el contexto"
        />
      ),
    },
    {
      title: "Asterisks",
      filtro: "asterisks",
      input: (
        <div className="select-container">
          <MultiSelect
            value={lazyParams.asterisks}
            options={optionsAsterisks}
            onChange={(e) => handleChange("asterisks", e.value)}
            optionLabel="name"
            filterBy="name"
            placeholder="Elige los asterisks"
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
