import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { IAsteriskType } from "../../types/asterisk.type";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import asteriskService from "../../services/asterisk.service";
import { AsteriskTable } from "./AsteriskTable";
import { Panel } from "../_slidePanel/Panel";
import { AsteriskPanelContain } from "./AsteriskPanelContain";
import { ConfirmationDialog } from "./ConfirmationDialog";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  serverName: string;
  active: string;
  ip: string;
}

export default function AsteriskAbm({ resetView }) {
  const asteriskEmpty = {
    id: "",
    serverName: "",
    active: true,
    ip: "",
    port: "",
    user: "",
    password: "",
    context: "",
    exten: "",
    orden: "",
    prefix: "",
    pathTTs: "",
  };

  const { vnConfiguracion: viewName, vfAbmAsterisks: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);

  const [loading, setLoading] = useState(false);
  const [asterisk, setAsterisk] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [activeAsteriskDialog, setActiveAsteriskDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    serverName: "",
    active: "",
    ip: "",
  };
  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      serverName: lazyParams.serverName,
      active: lazyParams.active,
      ip: lazyParams.ip,
      pageSize: lazyParams.rows,
      pageNumber: lazyParams.page,
      direction: lazyParams.direction ? direction : null,
      sortField: lazyParams.sortField,
    };

    loadLazyTimeout = asteriskService
      .ObtenerAsterisks(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.asterisks.map((c) => {
            let parsed: IAsteriskType = {
              id: c.id,
              serverName: c.serverName,
              ip: c.ip,
              port: c.port,
              user: c.user,
              password: c.password,
              context: c.context,
              exten: c.exten,
              orden: c.orden,
              prefix: c.prefix,
              pathTTs: c.pathTTs,
              active: c.active,
            };

            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    setAsterisk(asteriskEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const hideActiveAsteriskDialog = () => {
    setActiveAsteriskDialog(false);
  };

  const saveAsterisk = () => {
    const asteriskObj: IAsteriskType = {
      id: null,
      serverName: asterisk.serverName,
      ip: asterisk.ip,
      port: asterisk.port,
      user: asterisk.user,
      password: asterisk.password,
      context: asterisk.context,
      exten: asterisk.exten,
      orden: asterisk.orden,
      prefix: asterisk.prefix,
      pathTTs: asterisk.pathTTs,
      active: asterisk.active,
    };

    if (asterisk.id != "") {
      asteriskService
        .UpdateAsterisk(
          asterisk.id,
          asteriskObj,
          userName,
          viewName,
          viewFunction
        )
        .then((response: any) => {
          try {
            setAsterisk(asteriskObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Asterisk modificado",
              life: 3000,
            });
            setAsterisk(asteriskEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      asteriskService
        .CrearAsterisk(asteriskObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setAsterisk(asteriskObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Asterix guardado",
              life: 3000,
            });
            setAsterisk(asteriskEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setAsterisk(asteriskEmpty);
  };

  const editAsterisk = (asterisk) => {
    setAsterisk({ ...asterisk });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const confirmSwitchAsterisk = (asterisk) => {
    setAsterisk(asterisk);
    setActiveAsteriskDialog(true);
  };

  const switchAsterisk = () => {
    const strState = !asterisk.active ? "activado" : "desactivado";

    const id = asterisk.id;
    const updatePartial: IAsteriskType = {
      id: null,
      serverName: null,
      ip: null,
      port: null,
      user: null,
      password: null,
      context: null,
      exten: null,
      orden: null,
      prefix: null,
      pathTTs: null,
      active: !asterisk.active,
    };
    asteriskService
      .UpdateAsterisk(id, updatePartial, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setActiveAsteriskDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: `Asterisk ${asterisk.serverName} ${strState}`,
            life: 3000,
          });
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el cambio",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;

    let _asterisk = { ...asterisk };
    _asterisk[`${name}`] = val;
    setAsterisk(_asterisk);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    serverName: null,
    ip: null,
    port: null,
    user: null,
    password: null,
    context: null,
    exten: null,
    orden: null,
    prefix: null,
    pathTTs: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <AsteriskTable
            openNew={openNew}
            editAsterisk={editAsterisk}
            confirmSwitchAsterisk={confirmSwitchAsterisk}
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            footerPage={footerPage}
            loading={loading}
            resetView={resetView}
            initParams={initParams}
          />
          {asterisk && (
            <ConfirmationDialog
              asterisk={asterisk}
              activeAsteriskDialog={activeAsteriskDialog}
              hideActiveAsteriskDialog={hideActiveAsteriskDialog}
              switchAsterisk={switchAsterisk}
            />
          )}
          {asterisk && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <AsteriskPanelContain
                asterisk={asterisk}
                saveAsterisk={saveAsterisk}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
