import http from "../common/components/http-common";
import {
  IGeneralTypeRequest,
  IGeneralTypeRequestCreate,
} from "../types/general.type";

class GeneralService {
  ObtenerData(
    data,
    ruta,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        name: data.name,
        active: data.active,
        direction: data.direction,
        sortBy: data.field,
        size: data.pageSize,
        page: data.pageNumber,
      },
    };
    return http.get<any>(`/v1/${ruta}`, config);
  }

  CrearData(
    data: IGeneralTypeRequestCreate,
    ruta,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IGeneralTypeRequest>(`/v1/${ruta}`, data, config);
  }

  ModificarData(
    id,
    data: IGeneralTypeRequestCreate,
    ruta,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<IGeneralTypeRequest>(`/v1/${ruta}/` + id, data, config);
  }

  updatePartial(
    id,
    data,
    ruta,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<any>(`/v1/${ruta}/` + id, data, config);
  }
}

export default new GeneralService();
