import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { IVendorFilterType, IVendorType } from "../../types/vendor.type";
import vendorService from "../../services/vendor.service";
import asteriskService from "../../services/asterisk.service";
import { IAsteriskType } from "../../types/asterisk.type";
import { VendorTable } from "./VendorTable";
import { Panel } from "../_slidePanel/Panel";
import { VendorPanelContain } from "./VendorPanelContain";

export default function VendorAbm({ resetView }) {
  const vendorEmpty = {
    id: "",
    name: "",
    channel: "",
    cps: "",
    queueName: "",
    context: "",
    asterisks: [],
  };

  const [optionsAsterisks, setOptionsAsterisks] = useState(null);

  const { vnConfiguracion: viewName, vfAbmProveedores: viewFunction } =
    viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const initParams = {
    name: "",
    queueName: "",
    context: "",
    asterisks: "",
    direction: null,
    sortField: null,
    pageSize: 10,
    pageNumber: 1,
  };

  const [lazyParams, setLazyParams] = useState<IVendorFilterType | null>(
    initParams
  );

  const toast = useRef(null);
  let loadLazyTimeout = null;

  /** */
  const [asterisksTodos, setAsterisksTodos] = useState(null);

  useEffect(() => {
    getAsteriks();
  }, []);

  const getAsteriks = () => {
    let filterAst = {
      pageSize: 100,
      pageNumber: 1,
      sortField: null,
      direction: null,
      serverName: "",
      active: "",
      ip: "",
    };

    asteriskService
      .ObtenerAsterisks(filterAst, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.asterisks.map((c) => {
            let parsed: IAsteriskType = {
              id: c.id,
              serverName: c.serverName,
              ip: c.ip,
              port: c.port,
              user: c.user,
              password: c.password,
              context: c.context,
              exten: c.exten,
              orden: c.orden,
              prefix: c.prefix,
              pathTTs: c.pathTTs,
              active: c.active,
            };
            return parsed;
          });
          setAsterisksTodos(parsedData);

          setOptionsAsterisks(
            parsedData.map((a) => ({
              name: a.serverName,
              value: a.id,
            }))
          );
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };
  /** */

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter: IVendorFilterType = {
      name: lazyParams.name,
      queueName: lazyParams.queueName,
      context: lazyParams.context,
      asterisks: lazyParams.asterisks,
      direction: lazyParams.direction ? direction : null,
      sortField: lazyParams.sortField,
      pageSize: lazyParams.pageSize,
      pageNumber: lazyParams.pageNumber,
    };

    loadLazyTimeout = vendorService
      .ObtenerProveedores(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.vendors.map((c) => {
            let parsed: IVendorType = {
              id: c.id,
              name: c.name,
              channel: c.channel,
              cps: c.cps,
              queueName: c.queueName,
              context: c.context,
              asterisks: c.asterisks,
            };

            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);

          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    filterAsterisks([]);
    setVendor(vendorEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const saveVendor = () => {
    const vendorObj: IVendorType = {
      id: null,
      name: vendor.name,
      channel: vendor.channel,
      cps: vendor.cps,
      queueName: vendor.queueName,
      context: vendor.context,
      asterisks: vendor.asterisks,
    };

    if (vendor.id != "") {
      vendorService
        .UpdateVendor(vendor.id, vendorObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setVendor(vendorObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Proveedor modificado",
              life: 3000,
            });
            setVendor(vendorEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      vendorService
        .CrearVendor(vendorObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setVendor(vendorObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Proveedor guardado",
              life: 3000,
            });
            setVendor(vendorEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setVendor(vendorEmpty);
  };

  const editVendor = (vendor) => {
    filterAsterisks(vendor.asterisks);
    setVendor({ ...vendor });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const [sourceAsterisks, setSourceAsterisks] = useState(null);
  const filterAsterisks = (asterisks) => {
    setSourceAsterisks(
      asterisksTodos &&
        asterisksTodos.filter(
          (asterisk) => !asterisks.some((a) => a.id === asterisk.id)
        )
    );
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;

    let _vendor = { ...vendor };
    _vendor[`${name}`] = val;
    setVendor(_vendor);
  };

  const retornoPicklist = (selected) => {
    setVendor({ ...vendor, asterisks: selected });
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);

    setLazyParams((previousState) => {
      return {
        ...previousState,
        pageNumber: event.page + 1,
        pageSize: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.pageSize}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    name: null,
    channel: null,
    cps: null,
    queueName: null,
    context: null,
    asterisks: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />
          <VendorTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            editVendor={editVendor}
            resetView={resetView}
            initParams={initParams}
            optionsAsterisks={optionsAsterisks}
          />
          {vendor && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <VendorPanelContain
                vendor={vendor}
                saveVendor={saveVendor}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                sourceAsterisks={sourceAsterisks}
                setSourceAsterisks={setSourceAsterisks}
                retornoPickList={retornoPicklist}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
