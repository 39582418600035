import React, { useEffect } from "react";
import {
  Button,
  InputSwitch,
  InputText,
  Toolbar,
  classNames,
} from "primereact";

export const GeneralPanelContain = ({
  data,
  saveData,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  view,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [data]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!data.nombre.trim()) {
      newErrors.nombre = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.nombre = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle {view}</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveData();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="nombre">Nombre</label>
            <InputText
              id="nombre"
              value={data.nombre}
              onChange={(e) => onInputChange(e, "nombre")}
              required
              autoFocus
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && errors.nombre,
              })}
              placeholder="Ingresa el nombre"
            />
            {errors.nombre && (
              <small className="p-error block">{errors.nombre}</small>
            )}
          </div>

          <div className="field switch">
            <InputSwitch
              checked={data.estado}
              onChange={(e) => onInputChange(e, "estado")}
            />
            <label htmlFor="estado">Estado</label>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
